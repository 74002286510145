import React from 'react';
import Footer from './Footer';
import { Link } from 'react-router-dom';
//import homeImg from '../images/home-refrigeration.jpg';
import { useEffect } from 'react';
// import HomeInfo from './InfoOfServices/HomeRefrigeration';
// import ColdInfo from './InfoOfServices/ColdInfo';
// import CommercialInfo from './InfoOfServices/CommercialInfo';
// import ServiceInfo from './ServiceInfo';

import officeAC from '../images/ac/officeAC.jpg';
import comRef from '../images/ComRef/comRef.jpg';
import coldRoom from '../images/cold rooms/coldroom.jpg';
import ships from '../images/Transport/ship.jpg';
//import containers from '../images/Transport/contRef.jpg';
//import truck from '../images/Transport/truckRef.jpg';
//import trailer from '../images/Transport/trailerRef.jpg';
import contRef2 from '../images/Transport/contRef2.jpg';
import service from '../images/service123.jpg';

import ListItemIcon from '@mui/material/ListItemIcon';
import SnowflakeIcon from '@mui/icons-material/AcUnit';

function Services() {

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    cards.forEach((card) => {
      card.style.opacity = 0;
      card.style.transition = 'opacity 1s ease-in-out';
      setTimeout(() => {
        card.style.opacity = 1;
      }, 150);
    });
  }, []);

  return (
    <div className='pt-16 card opacity-0'>

      {/* <div className='bg-gray-200 rounded-full my-2 mx-2 shadow-xl'>
        <div className='max-w-5xl mx-auto py-6 px-4 sm:px-6 lg:px-8'>
          <h2 className='text-3xl md:text-5xl font-thin text-black'>What we offer</h2>
        </div>
      </div> */}

      <div className="hidden md:block md:mt-5 lg:mt-10">

        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-20 p-4">

          <div className="card flex flex-col md:flex-row items-center justify-evenly md:items-start opacity-0 xl:mx-14 2xl:mx-28">
            <img src={officeAC} alt="placeholder" className="w-full max-w-3xl object-contain mx-auto md:w-1/2 lg:max-w-2xl service-img rounded-2xl shadow-xl mt-5" />
            <div className="flex flex-col justify-center md:ml-8">
              <h2 className="text-3xl md:text-5xl font-thin mb-8 mt-5 text-center md:text-left">Air Conditioning</h2>
              <div className="text-gray-700 text-justify text-xl md:text-lg mx-2 md:text-justify service-para">
                <ul className="list-none flex flex-col gap-4">
                  <li className="flex items-start">
                    <ListItemIcon>
                      <SnowflakeIcon fontSize='small' className='mt-2' />
                    </ListItemIcon>
                    <span className="ml-2">Bespoke Residential: Our residential air conditioning solutions are custom-designed to suit your home's layout, ensuring year-round comfort and energy efficiency for your family's needs.</span>
                  </li>
                  <li className="flex items-start">
                    <ListItemIcon>
                      <SnowflakeIcon fontSize='small' className='mt-2' />
                    </ListItemIcon>
                    <span className="ml-2">Restaurants, Retail Shops, and Supermarkets: Enhance customer experiences in your commercial spaces with our specialized air conditioning installations, maintaining a pleasant ambiance that encourages longer stays and repeat visits.</span>
                  </li>
                  <li className="flex items-start">
                    <ListItemIcon>
                      <SnowflakeIcon fontSize='small' className='mt-2' />
                    </ListItemIcon>
                    <span className="ml-2">Data Centers, Medical Industries, and Food Industries: Our tailored cooling systems ensure precision temperature control, safeguarding critical IT infrastructure, maintaining product quality, and providing a hygienic environment for medical facilities.</span>
                  </li>
                  <li className="flex items-start">
                    <ListItemIcon>
                      <SnowflakeIcon fontSize='small' className='mt-2' />
                    </ListItemIcon>
                    <span className="ml-2">Factories, Hospitals, Hotel Kitchens, and Dark Kitchens: Create optimal working conditions for employees in diverse industries, enhancing productivity and overall well-being with our efficient and reliable air conditioning solutions.</span>
                  </li>
                </ul>
              </div>
              <Link to="/ResidentialAC">
                <button className=' text-xl rounded-full bg-gray-200 text-black px-8 py-3 mt-10 shadow-md hover:scale-110 transform transition duration-250 ease-in-out'>
                  More Details
                </button>
              </Link>
            </div>
          </div>


          {/* <ServiceInfo>
            <HomeInfo />
          </ServiceInfo> */}

        </div>

        <div className="mx-auto mt-8 pl-2 pr-2 pb-10">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-20">
            <div className="card flex flex-col md:flex-row items-center justify-evenly md:items-start opacity-0 xl:mx-14 2xl:mx-28">

              <div className="flex flex-col justify-center md:mx-8">
                <h2 className="text-3xl md:text-5xl font-thin text-center md:text-left mb-8 mt-5">Cold Rooms</h2>
                <div className="text-gray-700 text-justify md:text-lg mx-2 md:text-justify service-para">
                  <ul className="list-none flex flex-col gap-4">
                    {/* <li className="flex items-start">
                    <ListItemIcon>
                      <SnowflakeIcon fontSize='small' className='mt-2'/>
                    </ListItemIcon>
                    <span className="ml-2">Bespoke Residential: Our residential air conditioning solutions are custom-designed to suit your home's layout, ensuring year-round comfort and energy efficiency for your family's needs.</span>
                  </li> */}
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Restaurants, Retail Shops, and Supermarkets: Enhance the storage and display of temperature-sensitive items with our cold rooms, maintaining product quality and extending shelf life for your commercial establishments.</span>
                    </li>
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Medical Industries and Food Industries: Our cold room solutions provide critical cooling for data centers, medical supplies, and food storage, meeting strict temperature and safety regulations for these industries.</span>
                    </li>
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Factories and Warehouses: Optimize your industrial operations with our cold room installations, ensuring efficient storage and distribution of temperature-sensitive goods, such as pharmaceuticals and raw materials.</span>
                    </li>
                  </ul>
                </div>
                <Link to="/ColdRooms">
                  <button className=' text-xl rounded-full bg-gray-200 text-black px-8 py-3 mt-10 shadow-md hover:scale-110 transform transition duration-250 ease-in-out'>
                    More Details
                  </button>
                </Link>
              </div>
              <img src={coldRoom} alt="placeholder" className="w-full max-w-3xl object-contain mx-auto md:w-1/2 lg:max-w-2xl service-img rounded-2xl shadow-xl mt-5" />
            </div>
          </div>

          {/* <ServiceInfo>
            <ColdInfo />
          </ServiceInfo> */}

        </div>

        <div className="mx-auto mt-8 pl-2 pr-2 pb-10">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-20">
            <div className="card flex flex-col md:flex-row items-center justify-evenly md:items-start opacity-0 xl:mx-14 2xl:mx-28">
              <img src={service} alt="placeholder" className="w-full max-w-3xl object-contain mx-auto md:w-1/2 lg:max-w-2xl service-img rounded-2xl shadow-xl mt-5" />
              <div className="flex flex-col justify-center md:ml-8">
                <h2 className="text-3xl md:text-5xl font-thin mb-8 mt-5 text-center md:text-left">Maintenance and Repairs</h2>
                <div className="text-gray-700 text-justify text-xl md:text-lg mx-2 md:text-justify service-para">
                  <ul className="list-none flex flex-col gap-4">
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Nationwide Service: Climac provides a comprehensive maintenance and repair service that covers clients across the nation. No matter where your business is located, our team of skilled technicians is readily available to address your air conditioning, refrigeration, and cold room service needs. With our nationwide reach, you can rely on us for prompt and efficient support, ensuring minimal downtime and uninterrupted operations.</span>
                    </li>
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">24-Hour Call Outs: Emergencies can happen at any time, and we understand the importance of swift response when it comes to maintenance and repairs. That's why Climac offers 24-hour call-out services to attend to urgent issues promptly. Our dedicated team is on standby round the clock, ready to address critical situations and provide timely solutions to keep your systems running smoothly.</span>
                    </li>
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Experienced Technicians: Our maintenance and repair services are delivered by a team of highly experienced and skilled technicians. With years of expertise in the industry, they possess the knowledge and tools required to diagnose and fix a wide range of issues efficiently. Whether it's a faulty compressor, a refrigerant leak, or an air conditioning system breakdown, our technicians can troubleshoot and resolve problems with precision and professionalism. Trust Climac for reliable maintenance and repairs that ensure the longevity and optimal performance of your cooling systems.</span>
                    </li>
                  </ul>
                </div>
                <Link to="/Maintainance">
                  <button className=' text-xl rounded-full bg-gray-200 text-black px-8 py-3 mt-10 shadow-md hover:scale-110 transform transition duration-250 ease-in-out'>
                    More Details
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-8 pl-2 pr-2 pb-10">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-20">
            <div className="card flex flex-col md:flex-row items-center justify-evenly md:items-start opacity-0 xl:mx-14 2xl:mx-28">

              <div className="flex flex-col justify-center md:mx-8">
                <h2 className="text-3xl md:text-5xl font-thin mb-8 mt-5 text-center md:text-left">Commercial Refrigeration</h2>
                <div className="text-gray-700 text-justify text-xl md:text-lg mx-2 md:text-justify service-para">
                  <ul className="list-none flex flex-col gap-4">
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Restaurants, Retail Shops, and Supermarkets: Our expert commercial refrigeration solutions cater to the unique storage requirements of restaurants, retail shops, and supermarkets. We provide top-quality refrigeration systems that ensure optimal preservation of perishable goods, maintaining freshness and extending shelf life to enhance customer satisfaction.</span>
                    </li>
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Pubs/Clubs and Hotel Kitchens: Creating a delightful experience for patrons is crucial in the hospitality industry. Our specialized refrigeration installations for pubs/clubs and hotel kitchens ensure a steady supply of chilled beverages and fresh ingredients, allowing you to deliver exceptional services and elevate customer loyalty.</span>
                    </li>
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Medical Industries and Food Industries: In the medical and food sectors, precise temperature control is paramount to safeguarding product integrity and patient safety. Our commercial refrigeration solutions adhere to strict industry standards, offering reliable and hygienic storage for pharmaceuticals, vaccines, and food supplies.</span>
                    </li>
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Factories and Hospitals: As essential facilities, factories and hospitals require efficient and robust refrigeration solutions. Our commercial refrigeration units are designed to meet the diverse demands of industrial settings, ensuring consistent cooling for manufacturing processes and storing critical medical supplies.</span>
                    </li>
                  </ul>
                </div>
                <Link to="/CommercialRefrigeration">
                  <button className=' text-xl rounded-full bg-gray-200 text-black px-8 py-3 mt-10 shadow-md hover:scale-110 transform transition duration-250 ease-in-out'>
                    More Details
                  </button>
                </Link>
              </div>
              <img src={comRef} alt="placeholder" className="w-full max-w-3xl object-contain mx-auto md:w-1/2 lg:max-w-2xl service-img rounded-2xl shadow-xl mt-5" />
            </div>
          </div>

          {/* <ServiceInfo>
            <CommercialInfo />
          </ServiceInfo> */}

        </div>

        <div className="mx-auto mt-8 pl-2 pr-2 pb-10">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-20">
            <div className="card flex flex-col md:flex-row items-center justify-evenly md:items-start opacity-0 xl:mx-14 2xl:mx-28">
              <img src={contRef2} alt="placeholder" className="w-full max-w-3xl object-contain mx-auto md:w-1/2 lg:max-w-2xl service-img rounded-2xl shadow-xl mt-5" />
              <div className="flex flex-col justify-center md:ml-8">
                <h2 className="text-3xl md:text-5xl font-thin mb-8 mt-5 text-center md:text-left">Shipping Containers</h2>
                <div className="text-gray-700 text-justify text-xl md:text-lg mx-2 md:text-justify service-para">
                  <ul className="list-none flex flex-col gap-4">
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Shipping Container Air Conditioning: Our specialized air conditioning solutions for shipping containers ensure optimal temperature regulation, creating a comfortable and controlled environment within the containers. Whether used for transporting temperature-sensitive goods or serving as mobile offices, our air conditioning systems guarantee efficient cooling to meet diverse container requirements.</span>
                    </li>
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Shipping Container Refrigeration: With our cutting-edge refrigeration services for shipping containers, we enable safe and reliable transportation of perishable goods across long distances. Our refrigeration units are designed to maintain precise temperature levels, preserving the freshness and quality of perishables throughout their journey, making them an ideal choice for industries such as food, pharmaceuticals, and biotechnology.</span>
                    </li>
                  </ul>
                </div>
                <Link to="/ShippingContainers">
                  <button className=' text-xl rounded-full bg-gray-200 text-black px-8 py-3 mt-10 shadow-md hover:scale-110 transform transition duration-250 ease-in-out'>
                    More Details
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-8 pl-2 pr-2 pb-10">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-20">
            <div className="card flex flex-col md:flex-row items-center justify-evenly md:items-start opacity-0 xl:mx-14 2xl:mx-28">

              <div className="flex flex-col justify-center md:mx-8">
                <h2 className="text-3xl md:text-5xl font-thin mb-8 mt-5 text-center md:text-left">Transport</h2>
                <div className="text-gray-700 text-justify text-xl md:text-lg mx-2 md:text-justify service-para">
                  <ul className="list-none flex flex-col gap-4">
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Vehicle Air Conditioning: Climac offers top-notch air conditioning solutions tailored to a wide range of transport vehicles, including trailers, trucks, vans, boats, ships, buses, and more. Our expert team ensures that your passengers and cargo travel in comfort and convenience, regardless of the weather conditions outside. From compact vans to large commercial ships, our air conditioning systems deliver reliable performance and efficient cooling.</span>
                    </li>
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Transport Refrigeration: Our transport refrigeration services cater to the needs of industries relying on temperature-sensitive cargo transportation. Whether you operate refrigerated trailers, cold storage vans, or marine vessels, our refrigeration units are designed to maintain precise temperature control, ensuring the integrity and safety of your perishable goods. From pharmaceuticals to fresh produce, trust Climac to safeguard the quality and freshness of your transported items.</span>
                    </li>
                    <li className="flex items-start">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2' />
                      </ListItemIcon>
                      <span className="ml-2">Comprehensive Vehicle Solutions: At Climac, we take pride in offering comprehensive air conditioning and refrigeration solutions for all types of transport vehicles. From designing and installing custom cooling systems to providing regular maintenance and repairs, our team is committed to ensuring optimal performance and efficiency throughout your vehicle's lifecycle. Partner with us for reliable and seamless transport cooling solutions tailored to your specific requirements.</span>
                    </li>
                  </ul>
                </div>

                <Link to="/VansTrucks">
                  <button className=' text-xl rounded-full bg-gray-200 text-black px-8 py-3 mt-10 shadow-md hover:scale-110 transform transition duration-250 ease-in-out'>
                    More Details
                  </button>
                </Link>
              </div>
              <img src={ships} alt="placeholder" className="w-full max-w-3xl object-contain mx-auto md:w-1/2 lg:max-w-2xl service-img rounded-2xl shadow-xl mt-5" />
            </div>
          </div>
        </div>
      </div>

      {/*------------------------------------------------------------------------------------------------------------------------------------------------------------*/}

      {/* for mobile view */}
      <div className='block md:hidden'>
        <div className="mx-auto mt-8 pl-2 pr-2 pb-5">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-20">
            <div className="card flex flex-col md:flex-row items-center justify-evenly md:items-start opacity-0 xl:mx-14 2xl:mx-28">
              <img src={officeAC} alt="placeholder" className="w-full max-w-3xl object-contain mx-auto md:w-1/2 lg:max-w-2xl service-img rounded-2xl shadow-lg" />
              <div className="flex flex-col justify-center md:ml-8">
                <h2 className="text-3xl md:text-5xl font-normal mb-3 mt-4 text-center md:text-left">Air Conditioning</h2>
                <div className="text-gray-700 text-lg mx-2 service-para px-10">
                  <ul className="flex flex-col gap-4">
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Bespoke Residential Air Conditioning</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Restaurants, Retail Shops, and Supermarkets</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Data Centers, Medical Industries, and Food Industries</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Factories, Hospitals, Hotel Kitchens, and Dark Kitchens</span>
                    </li>
                  </ul>
                </div>
                <Link to="/ResidentialAC">
                  <button className=' text-xl rounded-full bg-gray-200 text-black px-8 py-3 mt-6 shadow-md hover:scale-110 transform transition duration-250 ease-in-out'>
                    More Details
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {/* <ServiceInfo>
            <HomeInfo />
          </ServiceInfo> */}

        </div>

        <div className="mx-auto mt-8 pl-2 pr-2 pb-5">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-20">
            <div className="card flex flex-col md:flex-row items-center justify-evenly md:items-start opacity-0 xl:mx-14 2xl:mx-28">
              <img src={coldRoom} alt="placeholder" className="w-full max-w-3xl object-contain mx-auto md:w-1/2 lg:max-w-2xl service-img rounded-2xl shadow-lg" />
              <div className="flex flex-col justify-center md:ml-8">
                <h2 className="text-3xl md:text-5xl font-normal mb-3 mt-4 text-center md:text-left">Cold Rooms</h2>
                <div className="text-gray-700 text-lg mx-2 service-para px-10">
                  <ul className="flex flex-col gap-4">
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Bespoke And Modular Cold Rooms</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Restaurants, Retail Shops, and Supermarkets</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Data Centers, Medical Industries, and Food Industries</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Factories, Hospitals, Hotel Kitchens, and Dark Kitchens</span>
                    </li>
                  </ul>
                </div>
                <Link to="/ColdRooms">
                  <button className=' text-xl rounded-full bg-gray-200 text-black px-8 py-3 mt-6 shadow-md hover:scale-110 transform transition duration-250 ease-in-out'>
                    More Details
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-8 pl-2 pr-2 pb-5">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-20">
            <div className="card flex flex-col md:flex-row items-center justify-evenly md:items-start opacity-0 xl:mx-14 2xl:mx-28">
              <img src={comRef} alt="placeholder" className="w-full max-w-3xl object-contain mx-auto md:w-1/2 lg:max-w-2xl service-img rounded-2xl shadow-lg" />
              <div className="flex flex-col justify-center md:ml-8">
                <h2 className="text-3xl md:text-5xl font-normal mb-3 mt-4 text-center md:text-left">Refrigeration</h2>
                <div className="text-gray-700 text-lg mx-2 service-para px-10">
                  <ul className="flex flex-col gap-4">
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Pubs/Clubs and Hotel Kitchens</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Restaurants, Retail Shops, and Supermarkets</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Medical Industries and Food Industries</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Factories and Hospitals</span>
                    </li>
                  </ul>
                </div>
                <Link to="/CommercialRefrigeration">
                  <button className=' text-xl rounded-full bg-gray-200 text-black px-8 py-3 mt-6 shadow-md hover:scale-110 transform transition duration-250 ease-in-out'>
                    More Details
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-8 pl-2 pr-2 pb-5">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-20">
            <div className="card flex flex-col md:flex-row items-center justify-evenly md:items-start opacity-0 xl:mx-14 2xl:mx-28">
              <img src={contRef2} alt="placeholder" className="w-full max-w-3xl object-contain mx-auto md:w-1/2 lg:max-w-2xl service-img rounded-2xl shadow-lg" />
              <div className="flex flex-col justify-center md:ml-8">
                <h2 className="text-3xl md:text-5xl font-normal mb-3 mt-4 text-center md:text-left">Shipping Containers</h2>
                <div className="text-gray-700 text-lg mx-2 service-para px-10">
                  <ul className="flex flex-col gap-4">
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Bespoke Residential Air Conditioning</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Restaurants, Retail Shops, and Supermarkets.</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Data Centers, Medical Industries, and Food Industries.</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Factories, Hospitals, Hotel Kitchens, and Dark Kitchens.</span>
                    </li>
                  </ul>
                </div>
                <Link to="/ShippingContainers">
                  <button className=' text-xl rounded-full bg-gray-200 text-black px-8 py-3 mt-6 shadow-md hover:scale-110 transform transition duration-250 ease-in-out'>
                    More Details
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-8 pl-2 pr-2 pb-5">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-20">
            <div className="card flex flex-col md:flex-row items-center justify-evenly md:items-start opacity-0 xl:mx-14 2xl:mx-28">
              <img src={service} alt="placeholder" className="w-full max-w-3xl object-contain mx-auto md:w-1/2 lg:max-w-2xl service-img rounded-2xl shadow-lg" />
              <div className="flex flex-col justify-center md:ml-8">
                <h2 className="text-3xl md:text-5xl font-normal mb-3 mt-4 text-center md:text-left">Maintenance and Repairs</h2>
                <div className="text-gray-700 text-lg mx-2 service-para px-10">
                  <ul className="flex flex-col gap-4">
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Nationwide Service</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">24-Hour Call Outs</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Highly experienced and Skilled engineers</span>
                    </li>
                  </ul>
                </div>
                <Link to="/Maintainance">
                  <button className=' text-xl rounded-full bg-gray-200 text-black px-8 py-3 mt-6 shadow-md hover:scale-110 transform transition duration-250 ease-in-out'>
                    More Details
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-8 pl-2 pr-2 pb-5">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-20">
            <div className="card flex flex-col md:flex-row items-center justify-evenly md:items-start opacity-0 xl:mx-14 2xl:mx-28">
              <img src={ships} alt="placeholder" className="w-full max-w-3xl object-contain mx-auto md:w-1/2 lg:max-w-2xl service-img rounded-2xl shadow-lg" />
              <div className="flex flex-col justify-center md:ml-8">
                <h2 className="text-3xl md:text-5xl font-normal mb-3 mt-4 text-center md:text-left">Transport</h2>
                <div className="text-gray-700 text-lg mx-2 service-para px-10">
                  <ul className="flex flex-col gap-4">
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Bespoke Air Conditioning and Refrigeration units</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Trucks, Vans, Buses, Boats, Ships, Caravans, Trailers and more</span>
                    </li>
                    <li className="flex flex-col items-center">
                      <ListItemIcon>
                        <SnowflakeIcon fontSize='small' className='mt-2 ml-3' />
                      </ListItemIcon>
                      <span className="ml-2">Custom Comfort Everywhere</span>
                    </li>
                  </ul>
                </div>
                <Link to="/VansTrucks">
                  <button className=' text-xl rounded-full bg-gray-200 text-black px-8 py-3 mt-6 shadow-md hover:scale-110 transform transition duration-250 ease-in-out'>
                    More Details
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
          @media (min-width: 768px) {
            .service-img {
              max-width: 100%;
            }
          }
          
          @media (min-width: 1024px) {
            .service-img {
              max-width: 50%;
            }
          }
          
          @media (min-width: 1280px) {
            .service-img {
              max-width: 33%;
            }
          }

          @media (max-width: 375px) {
            .service-para {
              font-size: 1rem;
              line-height: 1.25rem;
            }
          }

        `}
      </style>

      <Footer />
    </div >
  );
}

export default Services;
