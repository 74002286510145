import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import './ColdRooms.css';
import Footer from "../Footer";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import coldroom1 from "../../images/Transport/cont1.jpg";
import coldroom2 from "../../images/Transport/cont2.jpg";
import coldroom3 from "../../images/Transport/cont3.jpg";
import coldroom4 from "../../images/Transport/cont41.jpg";
import coldroom5 from "../../images/Transport/cont42.jpg";
import coldroom6 from "../../images/Transport/cont43.jpg";
import coldroom7 from "../../images/Transport/cont44.jpg";
import contRef from "../../images/Transport/contRef.jpg";
import contRef2 from "../../images/Transport/contRef2.jpg";
import contRef3 from "../../images/Transport/contRef3.png";
import contRef4 from "../../images/Transport/contRef4.jpg";
import ship2 from "../../images/Transport/ship2.jpg";

const ShippingContainers = () => {

  const history = useNavigate();
  function scrollToContact() {
    history("/");
    setTimeout(() => {
      const contactSection = document.getElementById("contact-section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  }

  const acItems = [
    {
      original: coldroom1,
      thumbnail: coldroom1,
      //description: "Offices",
    },
    {
      original: coldroom2,
      thumbnail: coldroom2,
      //description: "",
    },
    {
      original: coldroom3,
      thumbnail: coldroom3,
      //description: "Retail Stores",
    },
    {
      original: coldroom4,
      thumbnail: coldroom4,
      //description: "Bespoke Homes",
    },
    {
      original: coldroom5,
      thumbnail: coldroom5,
      //description: "Government Buildings",
    },
    {
      original: coldroom6,
      thumbnail: coldroom6,
      //description: "Data Centers Cooling",
    },
    {
      original: contRef4,
      thumbnail: contRef4,
      //description: "",
    },
    {
      original: coldroom7,
      thumbnail: coldroom7,
    },
  ];

  const topContainerRef = useRef(null);

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    cards.forEach((card) => {
      card.style.opacity = 0;
      card.style.transition = 'opacity 1s ease-in-out';
      setTimeout(() => {
        card.style.opacity = 1;
      }, 250);
    });

    const scrollToTopContainer = () => {
      window.scrollTo({
        top: topContainerRef.current.offsetTop,
        behavior: 'smooth',
      });
    };

    scrollToTopContainer();
  }, []);

  return (
    <div className="flex-col items-center card opacity-0">
      <div ref={topContainerRef} className="flex flex-col items-center pb-10 pt-20">
        <div className="topContainer">
          <h1 className="text-5xl font-thin md:text-7xl px-4">Shipping Containers</h1>
        </div>

        <div className="mt-8 mb-4 mx-4 lg:max-w-3xl shadow-xl rounded-xl overflow-hidden">
          <ImageGallery
            items={acItems}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            autoPlay={true}
            slideDuration={500}
            slideInterval={6000}
            showNav={false}
            showIndex={false}
            thumbnailPosition="bottom"
          />
        </div>

        <div className="text-center text-lg md:text-xl mb-8 p-4 xl:p-14 xl:px-18">

          <p className="p-4 font-light text-xl md:text-3xl text-red-500">
            "We are committed to Providing Cost-effective and Energy-efficient Solutions."
          </p>

          <div className="block sm:hidden items-center mt-3">
            <button
              className="rounded-full animate-bounce bg-red-500 text-gray-200 px-6 py-2 shadow-md hover:scale-110 transform transition duration-250 ease-in-out" onClick={scrollToContact}>
              Contact Us
            </button>
          </div>

          <div className="point-container flex flex-col sm:grid sm:grid-cols-2 gap-20 p-6 md:p-10">

            <div className="point mx-3 md:mx-10">
              <img src={contRef} alt="Commercial Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-md max-h-96  xl:w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Container Refrigeration Expertise</h3>
                <p className="text-justify">
                  Climac is your trusted partner in container refrigeration systems. With our extensive expertise, we meticulously engineer and install refrigeration solutions that ensure your shipping containers maintain precise temperature control. Our systems guarantee the safe transportation of temperature-sensitive cargo, providing peace of mind and quality assurance throughout the journey.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={contRef2} alt="Residential Air Conditioning" className="float-left mr-10 mb-10 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Bespoke Cold Room Solutions</h3>
                <p className="text-justify">
                  Climac specializes in crafting tailor-made cold room environments within shipping containers. Our team of experts works closely with you to design and install custom solutions that precisely meet your unique requirements. From temperature settings to spatial configurations, we ensure your containerized cold rooms are optimized for efficiency and functionality.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={contRef3} alt="Office Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Professional Container Installations</h3>
                <p className="text-justify">
                  At Climac, we take pride in our professional approach to container installations. Our highly skilled technicians handle every aspect of the process with precision and care. From securing the refrigeration units to ensuring proper insulation and ventilation, our installations are thorough and reliable, providing you with a turnkey solution you can trust.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={contRef4} alt="Retail Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96 xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Efficient Temperature Control</h3>
                <p className="text-justify">
                  Climac's container refrigeration systems are engineered for exceptional temperature control. We understand the critical importance of preserving the quality of your cargo during transit. Our systems maintain consistent temperatures, even in the most challenging conditions, safeguarding your goods and ensuring they arrive in perfect condition.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={ship2} alt="Retail Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96 xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Compact Cold Storage</h3>
                <p className="text-justify">
                  Discover the versatility of Climac's container-based cold storage solutions. Ideal for industries that require mobility and space efficiency, our systems maximize the utility of shipping containers. Whether you need temporary cold storage or a long-term solution, our compact designs provide a cost-effective and flexible option for your cold storage needs.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={coldroom3} alt="Retail Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96 xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Climate-Resilient Systems</h3>
                <p className="text-justify">
                  Climac's container refrigeration solutions are engineered to withstand diverse environmental conditions. From extreme heat to sub-zero temperatures, our systems are built to endure. We understand the value of climate-resilient refrigeration, ensuring your cargo remains secure and at the desired temperature, no matter where your containers travel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShippingContainers;