import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
// import Contact from './components/Contact';
import Navbar from './components/Navbar';
import './App.css';
import ColdRooms from './components/pages/ColdRooms';
import CommercialRefrigeration from './components/pages/CommercialRefrigeration';
import ResidentialAC from './components/pages/ResidentialAC';
import ShippingContainers from './components/pages/ShippingContainers';
import Trailers from './components/pages/Maintainance';
import VansTrucks from './components/pages/VansTrucks';

function App() {
  return (

    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/ColdRooms" element={<ColdRooms />} />
        <Route path="/CommercialRefrigeration" element={<CommercialRefrigeration />} />
        <Route path="/ResidentialAC" element={<ResidentialAC />} />
        <Route path="/ShippingContainers" element={<ShippingContainers />} />
        <Route path="/Maintainance" element={<Trailers />} />
        <Route path="/VansTrucks" element={<VansTrucks />} />
        {/* <Route path="/contact" element={<Contact />} /> */}
      </Routes>
    </div>

  );
}

export default App;