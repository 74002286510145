import { useState } from 'react';
import Footer from './Footer';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';

import emailjs from 'emailjs-com';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const templateParams = {
        name: name,
        email: email,
        message: message,
      };
  
      await emailjs.send('service_2tsjago', 'template_wxd9y1b', templateParams, '-jVocYGu-SaQMrC3Q');
  
      setIsSuccess(true);
      //alert('Message sent successfully!');
    } catch (error) {
      setIsError(true);
      console.error('EmailJS Error:', error);
      //alert('An error occurred. Please try again later.');
    }
  
    setIsLoading(false);
    setName('');
    setEmail('');
    setMessage('');
  };
  


  return (
    <div className="bg-gray-100 flex flex-col pt-5 lg:pt-16">

      <div className="flex-grow mb-2.5">

        <div className="px-4 sm:px-16 lg:px-4 flex-col">
          <h1 className="text-5xl font-thin mb-8 mt-10 lg:mt-4">Reach Us Here</h1>

          <div className='flex justify-center gap-2.5 lg:gap-5 m-6 animate-pulse'>
            <a href="https://www.facebook.com/profile.php?id=100093561980919" target="_blank" rel="noopener noreferrer">
              <FacebookRoundedIcon fontSize='large' className='text-gray-900 hover:text-blue-500 cursor-pointer hover:scale-125 transform transition duration-250 ease-in-out' />
            </a>
            <a href="https://www.instagram.com/ClimacUK" target="_blank" rel="noopener noreferrer">
              <InstagramIcon fontSize='large' className='text-gray-900 hover:text-pink-500 cursor-pointer hover:scale-125 transform transition duration-250 ease-in-out' />
            </a>
            <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer">
              <TwitterIcon fontSize='large' className='text-gray-900 hover:text-blue-300 cursor-pointer hover:scale-125 transform transition duration-250 ease-in-out' />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <LinkedInIcon fontSize='large' className='text-gray-900 hover:text-blue-600 cursor-pointer hover:scale-125 transform transition duration-250 ease-in-out' />
            </a>
            <a href="https://wa.me/+447470544722/?text=Hello, I would like to book an appointment with the Engineer." target="_blank" rel="noopener noreferrer">
              <WhatsAppIcon fontSize='large' className='text-gray-900 hover:text-green-500 cursor-pointer hover:scale-125 transform transition duration-250 ease-in-out' />
            </a>
            <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
              <YouTubeIcon fontSize='large' className='text-gray-900 hover:text-red-500 cursor-pointer hover:scale-125 transform transition duration-250 ease-in-out' />
            </a>
          </div>

          <p className='md:text-lg text-base mx-10'>
            Please contact us for a free quotation or any inquiries you may have. We strive to provide excellent customer service and will get back to you within 2 working days.
          </p>
        </div>

        <div className="container mb-3.5 mx-auto px-2 py-16 grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-0">

          <div className='bg-gray-50 rounded-3xl lg:mx-10 shadow-lg'>
            <div className="my-8 p-2">
              <EmailRoundedIcon className='text-blue-500 animate-bounce' />
              <h2 className="text-2xl font-semibold mb-3 text-red-500">Email</h2>
              <p className='text-lg'>info@climac.co.uk</p>
            </div>
            <div className="mb-10">
              <CallRoundedIcon className='text-blue-500 animate-bounce' />
              <h2 className="text-2xl font-semibold mb-3 text-red-500">Phone Number</h2>
              <p className='text-lg mb-1'>+44 02920 105560</p>
              <p className='text-lg mb-1'>+44 7470 544 722</p>
            </div>
            <div className='mb-10'>
              <LocationOnIcon className='text-blue-500 animate-bounce' />
              <h2 className="text-2xl font-semibold mb-3 text-red-500">Address</h2>
              <p className='text-lg mb-1'>15 Neptune Court</p>
              <p className='text-lg mb-1'>Vanguard Way</p>
              <p className='text-lg mb-1'>Cardiff - CF24 5PJ</p>
            </div>
          </div>

          <div className='bg-gray-50 rounded-3xl lg:mx-10 shadow-lg px-4'>
            <form onSubmit={handleSubmit} className="max-w-md mx-auto p-3.5 md:mt-12">
              <div className="flex flex-wrap -mx-2 my-4">
                <div className="w-full md:w-1/2 px-2">
                  <label htmlFor="name" className="block mb-2 font-semibold">
                    Name
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-2">
                  <label htmlFor="email" className="block mb-2 font-semibold">
                    Email
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Your@Email.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block mb-2 font-semibold">
                  How can we help you?
                </label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Type your message here..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="w-full h-48 px-4 py-2 border border-gray-400 rounded-lg resize-none focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className={`w-full bg-blue-500 text-white py-2 mb-6 rounded-lg hover:bg-blue-600 transition-colors ${isLoading && 'opacity-50 cursor-not-allowed'}`}
              >
                {isLoading ? 'Sending...' : 'Send'}
              </button>
              {isSuccess && (
                <div>
                  <p className="text-green-500 my-4">Thank you for reaching out to us!</p>
                  <p className="text-green-500 my-4">Our team will get back to you shortly.</p>
                </div>
              )}
              {isError && (
                <p className="text-red-500 my-4">
                  An error occurred. Please try again later.
                </p>
              )}
            </form>
          </div>

        </div>
      </div>
      <Footer />
    </div>


  );
};

export default Contact;
