import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../Footer";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import cafeRef from "../../images/ComRef/cafe.jpg";
import clubRef from "../../images/ComRef/club.jpg";
import comRef from "../../images/ComRef/comRef.jpg";
import hospitalRef from "../../images/ComRef/hospital.jpg";
import hotelRef from "../../images/ComRef/hotel.jpg";
import resortRef from "../../images/ComRef/resort.jpg";
import storeRef2 from "../../images/ComRef/store2.jpg";

const CommercialRefrigeration = () => {

  const history = useNavigate();
  function scrollToContact() {
    history("/");
    setTimeout(() => {
      const contactSection = document.getElementById("contact-section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  }

  const acItems = [
    {
      original: cafeRef,
      thumbnail: cafeRef,
      //description: "Offices",
    },
    {
      original: clubRef,
      thumbnail: clubRef,
      //description: "",
    },
    {
      original: comRef,
      thumbnail: comRef,
      //description: "Retail Stores",
    },
    {
      original: hospitalRef,
      thumbnail: hospitalRef,
      //description: "Bespoke Homes",
    },
    {
      original: hotelRef,
      thumbnail: hotelRef,
      //description: "Government Buildings",
    },
    {
      original: resortRef,
      thumbnail: resortRef,
      //description: "Data Centers Cooling",
    },
    {
      original: storeRef2,
      thumbnail: storeRef2,
      //description: "",
    },
  ];

  const topContainerRef = useRef(null);

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    cards.forEach((card) => {
      card.style.opacity = 0;
      card.style.transition = 'opacity 1s ease-in-out';
      setTimeout(() => {
        card.style.opacity = 1;
      }, 250);
    });

    const scrollToTopContainer = () => {
      window.scrollTo({
        top: topContainerRef.current.offsetTop,
        behavior: 'smooth',
      });
    };

    scrollToTopContainer();
  }, []);

  return (
    <div className="flex-col items-center card opacity-0">
      <div ref={topContainerRef} className="flex flex-col items-center pb-10 pt-20">
        <div className="topContainer">
          <h1 className="text-5xl font-thin md:text-7xl px-4">Commercial Refrigeration</h1>
        </div>

        <div className="mt-8 mb-4 mx-4 lg:max-w-3xl shadow-xl rounded-xl overflow-hidden">
          <ImageGallery
            items={acItems}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            autoPlay={true}
            slideDuration={500}
            slideInterval={6000}
            showNav={false}
            showIndex={false}
            thumbnailPosition="bottom"
          />
        </div>

        <div className="text-center text-lg md:text-xl mb-8 p-4 xl:p-14 xl:px-18">


          <p className="p-4 font-light text-xl md:text-3xl text-red-500">
            "We are committed to Providing Cost-effective and Energy-efficient Solutions."
          </p>

          <div className="block sm:hidden items-center mt-3">
            <button
              className="rounded-full animate-bounce bg-red-500 text-gray-200 px-6 py-2 shadow-md hover:scale-110 transform transition duration-250 ease-in-out" onClick={scrollToContact}>
              Contact Us
            </button>
          </div>

          <div className="point-container flex flex-col sm:grid sm:grid-cols-2 gap-20 p-6 md:p-10">

            <div className="point mx-3 md:mx-10">
              <img src={cafeRef} alt="Commercial Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-md max-h-96  xl:w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Restaurants and Cafes</h3>
                <p className="text-justify">
                  Our expertise in commercial refrigeration ensures that restaurants and cafes can rely on cutting-edge cooling solutions to preserve ingredients' quality and extend their shelf life.
                  Whether it's walk-in coolers, display cases, or custom refrigeration units, our tailored systems guarantee optimal temperature control, safeguarding the integrity of your culinary
                  creations and contributing to a seamless dining experience.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={clubRef} alt="Residential Air Conditioning" className="float-left mr-10 mb-10 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Pubs and Clubs</h3>
                <p className="text-justify">
                  Create a chill atmosphere in your pubs and clubs with Climac's specialized commercial refrigeration solutions.
                  From storing a variety of beverages to keeping ingredients for signature cocktails at the perfect temperature, our refrigeration systems ensure that your patrons enjoy their
                  favorite drinks just the way they should be.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={comRef} alt="Office Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Warehouses and Industries</h3>
                <p className="text-justify">
                  In the world of industries and warehouses, maintaining optimal conditions for stored goods is paramount.
                  Climac's industrial refrigeration systems offer unparalleled reliability and efficiency, catering to diverse sectors.
                  From pharmaceuticals to manufacturing, our solutions ensure precise temperature control, guaranteeing the quality and integrity of products throughout their storage period.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={hospitalRef} alt="Public Sector Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Hospitals</h3>
                <p className="text-justify">
                  Climac recognizes the critical importance of refrigeration in healthcare facilities.
                  Our advanced refrigeration systems play a vital role in preserving medical supplies, pharmaceuticals, and sensitive materials that require stringent temperature control.
                  With our reliable units, hospitals can maintain the integrity of their inventory, ensuring the safety and well-being of patients and healthcare professionals.
                  We are dedicated to providing tailored solutions that align with the high standards of the medical industry, supporting the seamless operation of healthcare institutions.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={hotelRef} alt="Evaporative Cooling Systems" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Hotels</h3>
                <p className="text-justify">
                  Elevate guest experiences with Climac's state-of-the-art refrigeration solutions tailored for the hospitality sector.
                  From mini-bars in hotel rooms to large-scale kitchen refrigeration, our systems cater to the diverse needs of hotels.
                  We ensure that perishable items remain fresh and appetizing, enabling culinary teams to craft exceptional dishes and beverages.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={resortRef} alt="Retail Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96 xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Resorts</h3>
                <p className="text-justify">
                  At Climac, we understand the unique requirements of luxury resorts.
                  Our specialized refrigeration solutions are designed to enhance the guest experience in resorts.
                  From poolside bars to expansive restaurants, our systems ensure the preservation of ingredients, beverages, and culinary creations at the ideal temperatures.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={storeRef2} alt="Data Centre Cooling" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Supermarkets</h3>
                <p className="text-justify">
                  Efficiency and freshness are key in the competitive supermarket landscape, and Climac excels in providing cutting-edge refrigeration solutions to meet these demands.
                  From open-display coolers to walk-in freezers, our systems ensure food safety and prolong shelf life, helping supermarkets offer quality products while minimizing waste.
                  With customizable configurations, we empower supermarkets to enhance their offerings and create a positive shopping experience for customers.
                </p>
              </div>
            </div>
          </div>

        </div>

      </div>
      <Footer />
    </div>
  );
};

export default CommercialRefrigeration;
